import loadable from '@loadable/component';
import {
  ROUTE_NARRATIVE_COMMUNITY_DETAIL,
  ROUTE_NARRATIVE,
  ROUTE_NARRATIVE_COMMUNITY_UPDATE_DETAIL,
} from '@/constants/routes';

const ContentContainer = loadable(
  () => import('@/containers/ContentContainer')
);
const NotFound = loadable(() => import('@/pages/NotFound'));
const Oops = loadable(() => import('@/components/Oops'));
const Timeout = loadable(() => import('@/components/Timeout'));
const Maintenance = loadable(() => import('@/pages/Maintenance'));
const NarrativeNotFound = loadable(
  () => import('@/pages/Narrative/components/NarrativeNotFound'),
  {
    resolveComponent: (m) => m.NarrativeNotFound,
  }
);

type Props = {
  pageStatus: number;
  routeName: string;
};

export const ContentSimplyWallSt = ({ pageStatus, routeName }: Props) => {
  if (pageStatus === 404) {
    if (
      [
        ROUTE_NARRATIVE,
        ROUTE_NARRATIVE_COMMUNITY_DETAIL,
        ROUTE_NARRATIVE_COMMUNITY_UPDATE_DETAIL,
      ].includes(routeName)
    ) {
      return (
        <ContentContainer>
          <NarrativeNotFound />
        </ContentContainer>
      );
    }
    return <NotFound />;
  }
  if (pageStatus === 408) return <Timeout />;
  if (pageStatus === 503) return <Maintenance />;
  if (pageStatus >= 200 && pageStatus < 300) return <ContentContainer />;
  if (pageStatus >= 400 && pageStatus < 600) return <Oops />;

  return null;
};
