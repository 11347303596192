import type { LocationState, ConfirmLeave } from 'redux-first-router';
import type { AnyAction, Dispatch } from 'redux';
import {
  fetchPortfolioAtLoad,
  fetchPortfolioNewsAtLoad,
  fetchPublicPortfolioNewsAtLoad,
} from '@/components/DeprecatedPortfolio/redux/actions';
import { fetchPublicPortfolio } from '@/components/DeprecatedPortfolio/redux/routines';
import {
  fetchCompanies,
  fetchCompany,
} from '@/pages/CompanyReport/redux/routines';
import { detectMarket } from '@ducks/shared/routines';

import {
  ROUTE_ABOUT,
  ROUTE_ACCOUNT_RECOVERY,
  ROUTE_ACCOUNT_VALIDATION,
  ROUTE_AFFILIATE_PROGRAM,
  ROUTE_ARTICLE,
  ROUTE_BUSINESS,
  ROUTE_CAREERS,
  ROUTE_COMMUNITY_IDEA,
  ROUTE_COMPANY,
  ROUTE_COMPANY_WITH_EVENTS,
  ROUTE_COMPARE_COMPANY,
  ROUTE_COMPARE_HOME,
  ROUTE_CREATE_CRITERIA,
  ROUTE_NARRATIVE_COMMUNITY,
  ROUTE_NARRATIVE_COMMUNITY_PROFILE,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_WITH_EVENTS,
  ROUTE_DELETE_ACCOUNT,
  ROUTE_DEPRECATED_IMPORT_PORTFOLIO,
  ROUTE_DEPRECATED_PORTFOLIO,
  ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN,
  ROUTE_DEPRECATED_PORTFOLIO_LIST,
  ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT,
  ROUTE_DEPRECATED_PORTFOLIO_SHARE,
  ROUTE_DEPRECATED_PUBLIC_PORTFOLIO,
  ROUTE_MY_PORTFOLIOS,
  ROUTE_DISCOVER_INVESTING_IDEAS,
  ROUTE_DISCOVER_INVESTING_IDEAS_MARKET,
  ROUTE_DISCOVER_SAMPLE_PORTFOLIOS,
  ROUTE_EDIT_EXPLORE_IDEA,
  ROUTE_EDIT_USER_IDEA,
  ROUTE_HOME,
  ROUTE_INVESTING_IDEA_HOME,
  ROUTE_INVESTING_IDEA_MARKET,
  ROUTE_INVESTING_IDEA_MARKET_INDUSTRY,
  ROUTE_MAINTENANCE,
  ROUTE_MARKET,
  ROUTE_MARKET_HOME,
  ROUTE_MARKET_HUB,
  ROUTE_MARKET_SECTOR,
  ROUTE_MARKET_SECTOR_SECONDARY,
  ROUTE_MARKET_SECTOR_SECONDARY_TERTIARY,
  ROUTE_NARRATIVE,
  ROUTE_OOPS,
  ROUTE_OTP_LOGIN,
  ROUTE_PLANS,
  ROUTE_PODCASTS,
  ROUTE_PORTFOLIO,
  ROUTE_PORTFOLIO_V2,
  ROUTE_PORTFOLIO_DEFAULT,
  ROUTE_PORTFOLIO_DEMO,
  ROUTE_PORTFOLIO_PUBLIC,
  ROUTE_PORTFOLIO_LINKING_SUCCESS,
  ROUTE_PORTFOLIO_NEW,
  ROUTE_PORTFOLIO_TRANSACTIONS_CREATE,
  ROUTE_PORTFOLIO_TRANSACTIONS_DISCARD_PORTFOLIO,
  ROUTE_PORTFOLIO_TRANSACTIONS_EDIT,
  ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS,
  ROUTE_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING,
  ROUTE_PORTFOLIO_NARRATIVES,
  ROUTE_PORTFOLIO_HOLDINGS,
  ROUTE_PORTFOLIO_ANALYSIS,
  ROUTE_PORTFOLIO_DIVIDENDS,
  ROUTE_PORTFOLIO_RETURNS,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PRIVACY_POLICY_FULL,
  ROUTE_PRO_API,
  ROUTE_STOCKS_HOME,
  ROUTE_STOCKS_MARKET,
  ROUTE_STOCKS_MARKET_INDUSTRY,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_TERMS_AND_CONDITIONS_FULL,
  ROUTE_USER_BETA_LAB,
  ROUTE_USER_LOGOUT,
  ROUTE_USER_NOTIFICATIONS,
  ROUTE_USER_NOTIFICATIONS_LOCALE,
  ROUTE_USER_PLAN,
  ROUTE_USER_PROFILE,
  ROUTE_USER_REGISTER,
  ROUTE_USER_SUBSCRIPTION,
  ROUTE_USER_SUBSCRIPTION_LOCALE,
  ROUTE_USER_SUBSCRIPTION_AND_BILLING,
  ROUTE_USER_SUBSCRIPTION_AND_BILLING_LOCALE,
  ROUTE_USER_UNSUBSCRIBE,
  ROUTE_WATCHLIST,
  ROUTE_WATCHLIST_WITH_EVENTS,
  ROUTE_WELCOME,
  ROUTE_LOCALE_COMPANY,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
  ROUTE_PUBLIC_NARRATIVE_CREATE,
  ROUTE_PUBLIC_NARRATIVE_EDIT,
  ROUTE_PARTNER_REGISTER,
  ROUTE_PARTNER_LOGIN,
  ROUTE_PARTNER_ERROR,
  ROUTE_PARTNER_CLAIM,
  ROUTE_SUBSCRIPTION_EXTENSION,
  ROUTE_PASSWORD_RESET,
  ROUTE_SOCIAL_TO_LOCAL,
  ROUTE_EMAIL_CHANGE,
  ROUTE_NARRATIVE_COMMUNITY_DETAIL,
  ROUTE_PARTNER_AVANZA_CLAIM,
  ROUTE_PARTNER_AVANZA_REGISTER,
  ROUTE_PARTNER_AVANZA_LOGIN,
  ROUTE_PARTNER_AVANZA_ERROR,
  ROUTE_GIFT_CARD_PURCHASE,
  ROUTE_GIFT_CARD_REDEEM,
  ROUTE_WELCOME_LOCALE,
  ROUTE_USER_REGISTER_LOCALE,
  ROUTE_ACCOUNT_VALIDATION_LOCALE,
  ROUTE_ACCOUNT_RECOVERY_LOCALE,
  ROUTE_NARRATIVE_COMMUNITY_UPDATE_DETAIL,
  ROUTE_USER_PROFILE_LOCALE,
  ROUTE_NARRATIVE_UPDATE_EDIT,
} from '@/constants/routes';
import {
  TRACKING_ARTICLE_PAGE,
  TRACKING_COMMUNITY_IDEA,
  TRACKING_COMPANY,
  TRACKING_DASHBOARD,
  TRACKING_DISCOVER_INVESTING_IDEAS,
  TRACKING_DISCOVER_SAMPLE_PORTFOLIOS,
  TRACKING_HOME,
  TRACKING_INVESTING_IDEA,
  TRACKING_MARKET_HUB,
  TRACKING_MARKET_PAGE,
  TRACKING_PORTFOLIO,
  TRACKING_PORTFOLIO_NEW,
  TRACKING_PORTFOLIO_TRANSACTIONS_CREATE,
  TRACKING_PORTFOLIO_TRANSACTIONS_EDIT,
  TRACKING_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING,
  TRACKING_PORTFOLIO_DEMO,
  TRACKING_PORTFOLIO_PUBLIC,
  TRACKING_SCREENER,
  TRACKING_STOCKS_HOME,
  TRACKING_USER_BETA_LAB,
  TRACKING_USER_LOGOUT,
  TRACKING_USER_NOTIFICATIONS,
  TRACKING_USER_PLAN,
  TRACKING_USER_PORTFOLIO,
  TRACKING_USER_PROFILE,
  TRACKING_USER_REGISTER,
  TRACKING_USER_SUBSCRIPTION,
  TRACKING_USER_SUBSCRIPTION_AND_BILLING,
  TRACKING_WATCHLIST,
  TRACKING_PORTFOLIO_LINKING_SUCCESS,
  TRACKING_NARRATIVE_COMMUNITY,
  TRACKING_NARRATIVE_COMMUNITY_PROFILE,
  TRACKING_GIFT_CARD_REDEEM,
  TRACKING_GIFT_CARD_PURCHASE,
} from '@/constants/tracking';
import type { SortBy } from '@/pages/Stocks/hooks/useSortByOptions';
import { setPreferredLanguage } from '@simplywallst/services';
import { setPreferredLanguage as setPreferredLanguageGQL } from '@/utilities/graphQLClient';
import type { SwsTeam } from '@/components/Sentry';
import {
  SUB_ROUTE_CALENDAR,
  SUB_ROUTE_SCREENERS,
  SUB_ROUTE_UPDATES,
} from '@/components/Dashboard/redux/constants';
import type { RouteCacheKeyFn } from './utils';

export interface State {
  location: LocationState;
}

interface LayoutObject {
  desktopTopbar?: boolean;
  mobileTopbar?: boolean;
  mobileFooterMenu?: boolean;
  mobileTopbarSubNav?: boolean;
}

type RouteAction = (payload: any, state: State) => AnyAction;

interface RouteObject {
  name: string;
  path: string;
  actions?: RouteAction[];
  needsAuthentication?: boolean;
  dispatcher?: (
    dispatch: Dispatch,
    getState: () => State,
    action?: RouteAction[]
  ) => void;
  layout?: LayoutObject;
  pageName?: string;
  team?: SwsTeam;
  cacheable?: boolean;
  cacheDurationSeconds?: number;
  cacheKey?: RouteCacheKeyFn;
  locale?: string;
  confirmLeave?: ConfirmLeave;
}

type RouteThunk = (
  route: RouteObject
) => (dispatch: Dispatch, getState: () => State) => void;

export interface Route {
  path: string;
  capitalizedWords: boolean;
  thunk: ReturnType<RouteThunk>;
  layout: null | LayoutObject;
  pageName?: string;
  needsAuthentication?: boolean;
  team?: SwsTeam;
  cacheable: boolean;
  cacheDurationSeconds: number;
  cacheKey?: RouteCacheKeyFn;
}

export type RoutesMap = Record<RouteObject['name'], Route>;

/**
 * Dashboard routes are subject to API Gateway configuration. If you need to add a
 * dashboard sub-route, as well as updating this regex, you'll need to update the
 * terraform configuration to specify the new sub-route as an application route.
 * See mono/terraform/main.tf for more details.
 */
const dashboardSubRoutesRegex = [
  SUB_ROUTE_UPDATES,
  SUB_ROUTE_SCREENERS,
  SUB_ROUTE_CALENDAR,
].join('|');

export const sortByList: SortBy[] = [
  'market-cap-large',
  'market-cap-small',
  'top-gainers',
  'biggest-losers',
  'growth',
  'dividend-yield-high',
];
const sortByRouteMatcherRegex = sortByList.join('|');

const routeThunk: RouteThunk = (route) => async (dispatch, getState) => {
  const state = getState();
  const { location } = state;

  const currentLocale = location.payload?.locale || 'en';
  try {
    setPreferredLanguage(currentLocale);
    setPreferredLanguageGQL(currentLocale);
  } catch {
    /** silently ignore */
  }
  try {
    if (
      decodeURIComponent(location.pathname) !==
      decodeURIComponent(location.prev.pathname)
    ) {
      if (route.dispatcher) {
        // sometimes routes want custom actions on load
        route.dispatcher(dispatch, getState, route.actions);
      } else if (route.actions) {
        route.actions.forEach((action) => dispatch(action(location, state)));
      }
    }
    // And this too?
    await Promise.resolve('Thunk ran...');
  } catch (err) {
    console.error('Failed to run thunk..', err);
  }
};

const mapRoutes = (routes: RouteObject[]): RoutesMap =>
  routes.reduce((acc, current) => {
    acc[current.name] = {
      path: current.path,
      capitalizedWords: false,
      thunk: routeThunk(current),
      layout: current.layout || null,
      pageName: current.pageName || undefined,
      needsAuthentication: current.needsAuthentication,
      team: current.team,
      cacheable:
        typeof current.cacheable === 'undefined' ? true : current.cacheable,
      cacheDurationSeconds:
        typeof current.cacheDurationSeconds === 'undefined'
          ? 43200
          : current.cacheDurationSeconds,
      cacheKey: current.cacheKey,
      confirmLeave: current.confirmLeave,
    };
    return acc;
  }, {});

const routes: RouteObject[] = [
  {
    name: ROUTE_HOME,
    path: '/',
    pageName: TRACKING_HOME,
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_OOPS,
    path: '/oops',

    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_WELCOME,
    path: '/welcome',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_WELCOME_LOCALE,
    path: '/:locale/welcome',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_AVANZA_CLAIM,
    path: '/partner/avanza/link',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_AVANZA_REGISTER,
    path: '/partner/avanza/register',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_AVANZA_LOGIN,
    path: '/partner/avanza/login',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_AVANZA_ERROR,
    path: '/partner/avanza/error',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_CLAIM,
    path: '/partner/claim',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_REGISTER,
    path: '/partner/register',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_LOGIN,
    path: '/partner/login',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_PARTNER_ERROR,
    path: '/partner/error',
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    needsAuthentication: false,
  },
  {
    name: ROUTE_DEPRECATED_PORTFOLIO,
    path: '/portfolio/my-portfolios/:portfolioId/:name?',
    pageName: TRACKING_PORTFOLIO,
    layout: {
      mobileTopbarSubNav: true,
    },
    actions: [fetchPortfolioAtLoad, fetchPortfolioNewsAtLoad],
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_DEPRECATED_PUBLIC_PORTFOLIO,
    path: '/portfolio/discover/:portfolioId/:name',
    layout: {
      mobileTopbarSubNav: true,
    },
    actions: [fetchPublicPortfolio.trigger, fetchPublicPortfolioNewsAtLoad],
    needsAuthentication: false,
    team: 'wombats',
  },
  {
    name: ROUTE_DEPRECATED_PORTFOLIO_LIST,
    path: '/portfolio',
    needsAuthentication: false,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_V2,
    path: '/portfolio/v2',
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_DEFAULT,
    path: '/portfolio/default',
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_DEMO,
    path: '/portfolio/demo',
    pageName: TRACKING_PORTFOLIO_DEMO,
    needsAuthentication: false,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_PUBLIC,
    path: '/portfolio/public/:slug',
    pageName: TRACKING_PORTFOLIO_PUBLIC,
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_PORTFOLIO_NEW,
    path: '/portfolio/new',
    pageName: TRACKING_PORTFOLIO_NEW,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_TRANSACTIONS_CREATE,
    path: '/portfolio/:portfolioId/transactions/create',
    pageName: TRACKING_PORTFOLIO_TRANSACTIONS_CREATE,
    needsAuthentication: true,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    team: 'wombats',
    confirmLeave: (_, action) => {
      if (
        'type' in action &&
        action.type === ROUTE_PORTFOLIO_TRANSACTIONS_DISCARD_PORTFOLIO
      ) {
        return;
      }
      if (
        'type' in action &&
        action.type === ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS
      ) {
        return;
      }
      return 'Changes that you made may not be saved. Are you sure you want to leave?';
    },
  },
  {
    name: ROUTE_PORTFOLIO_TRANSACTIONS_DISCARD_PORTFOLIO,
    path: '/portfolio/discard-portfolio',
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_TRANSACTIONS_EDIT,
    path: '/portfolio/:portfolioId/transactions/edit',
    pageName: TRACKING_PORTFOLIO_TRANSACTIONS_EDIT,
    needsAuthentication: true,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    team: 'wombats',
    confirmLeave: (_, action) => {
      if (
        'type' in action &&
        action.type === ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS
      ) {
        return;
      }
      return 'Changes that you made may not be saved. Are you sure you want to leave?';
    },
  },
  {
    name: ROUTE_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING,
    path: '/portfolio/:portfolioId/transactions/edit-holding/:holdingId',
    pageName: TRACKING_PORTFOLIO_TRANSACTIONS_EDIT_HOLDING,
    needsAuthentication: true,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    team: 'wombats',
    confirmLeave: (_, action) => {
      if (
        'type' in action &&
        action.type === ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS
      ) {
        return;
      }
      return 'Changes that you made may not be saved. Are you sure you want to leave?';
    },
  },
  {
    name: ROUTE_PORTFOLIO_HOLDINGS,
    path: '/portfolio/:portfolioId/holdings',
    pageName: TRACKING_PORTFOLIO,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_NARRATIVES,
    path: '/portfolio/:portfolioId/narratives',
    pageName: TRACKING_PORTFOLIO,
    needsAuthentication: true,
    team: 'narwhals',
  },
  {
    name: ROUTE_PORTFOLIO_ANALYSIS,
    path: '/portfolio/:portfolioId/analysis',
    pageName: TRACKING_PORTFOLIO,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_DIVIDENDS,
    path: '/portfolio/:portfolioId/dividends',
    pageName: TRACKING_PORTFOLIO,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_RETURNS,
    path: '/portfolio/:portfolioId/returns',
    pageName: TRACKING_PORTFOLIO,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_TRANSACTIONS_SAVE_SUCCESS,
    path: '/portfolio/:portfolioId/transactions/edit-success',
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO_LINKING_SUCCESS,
    path: '/portfolio/linking-success/:provider?',
    pageName: TRACKING_PORTFOLIO_LINKING_SUCCESS,
    needsAuthentication: true,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    team: 'wombats',
  },
  {
    name: ROUTE_MY_PORTFOLIOS,
    path: '/portfolio/my-portfolios',
    pageName: TRACKING_USER_PORTFOLIO,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_PORTFOLIO,
    path: '/portfolio/:portfolioId',
    pageName: TRACKING_PORTFOLIO,
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_DISCOVER_INVESTING_IDEAS,
    path: '/discover/investing-ideas',
    actions: [detectMarket.trigger],
    pageName: TRACKING_DISCOVER_INVESTING_IDEAS,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 3600,
  },
  {
    name: ROUTE_DISCOVER_INVESTING_IDEAS_MARKET,
    path: '/discover/:market/investing-ideas',
    pageName: TRACKING_DISCOVER_INVESTING_IDEAS,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 3600,
  },
  {
    name: ROUTE_INVESTING_IDEA_HOME,
    path: '/discover/investing-ideas/:id/:name',
    actions: [detectMarket.trigger],
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 3600,
  },
  {
    name: ROUTE_INVESTING_IDEA_MARKET,
    path: '/discover/investing-ideas/:id/:name/:market',
    pageName: TRACKING_INVESTING_IDEA,
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 3600,
  },
  {
    name: ROUTE_INVESTING_IDEA_MARKET_INDUSTRY,
    path: '/discover/investing-ideas/:id/:name/:market/:industry',
    pageName: TRACKING_INVESTING_IDEA,
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 3600,
  },
  /**
   * Just for redirection
   *
   * /discover/discover/community-ideas/:id/:name
   *
   * => /discover/investing-ideas
   */
  {
    name: ROUTE_COMMUNITY_IDEA,
    path: `/discover/community-ideas/:id/:name`,
    pageName: TRACKING_COMMUNITY_IDEA,
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_DISCOVER_SAMPLE_PORTFOLIOS,
    path: '/discover/sample-portfolios',
    pageName: TRACKING_DISCOVER_SAMPLE_PORTFOLIOS,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: false,
    team: 'wombats',
  },
  {
    name: ROUTE_DASHBOARD,
    pageName: TRACKING_DASHBOARD,
    path: `/dashboard/:subRoute(${dashboardSubRoutesRegex})?`,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_DASHBOARD_WITH_EVENTS,
    pageName: TRACKING_DASHBOARD,
    path: `/dashboard/:subRoute(${dashboardSubRoutesRegex})?/:event/:eventId?`,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_COMPANY,
    path: '/stocks/:isoCode2/:industry/:exchange_ticker/:companyname/:section?',
    pageName: TRACKING_COMPANY,
    layout: {
      mobileTopbarSubNav: true,
    },
    actions: [fetchCompany.trigger],
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 21600,
  },
  {
    name: ROUTE_COMPANY_WITH_EVENTS,
    path: '/stocks/:isoCode2/:industry/:exchange_ticker/:companyname/event/:eventId?',
    pageName: TRACKING_COMPANY,
    layout: {
      mobileTopbarSubNav: true,
    },
    actions: [fetchCompany.trigger],
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_LOCALE_COMPANY,
    path: '/:locale/stocks/:isoCode2/:industry/:exchange_ticker/:companyname/:section?',
    pageName: TRACKING_COMPANY,
    layout: {
      mobileTopbarSubNav: true,
    },
    actions: [fetchCompany.trigger],
    needsAuthentication: false,
    team: 'dingoes',
    cacheDurationSeconds: 21600,
  },
  {
    name: ROUTE_LOCALE_COMPANY_WITH_EVENTS,
    path: '/:locale/stocks/:isoCode2/:industry/:exchange_ticker/:companyname/event/:eventId?',
    pageName: TRACKING_COMPANY,
    layout: {
      mobileTopbarSubNav: true,
    },
    actions: [fetchCompany.trigger],
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_COMPARE_COMPANY,
    path: '/compare/:symbols',
    actions: [fetchCompanies.trigger],
    needsAuthentication: false,
    team: 'dingoes',
    cacheable: false,
  },
  {
    name: ROUTE_COMPARE_HOME,
    path: '/compare',
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_USER_PROFILE,
    path: '/user/profile',
    pageName: TRACKING_USER_PROFILE,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_PROFILE_LOCALE,
    path: '/:locale/user/profile',
    pageName: TRACKING_USER_PROFILE,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_NOTIFICATIONS,
    path: '/user/notifications',
    pageName: TRACKING_USER_NOTIFICATIONS,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_NOTIFICATIONS_LOCALE,
    path: '/:locale/user/notifications',
    pageName: TRACKING_USER_NOTIFICATIONS,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_PLAN,
    path: '/user/plan',
    pageName: TRACKING_USER_PLAN,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_SUBSCRIPTION,
    path: '/user/subscription',
    pageName: TRACKING_USER_SUBSCRIPTION,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_SUBSCRIPTION_LOCALE,
    path: '/:locale/user/subscription',
    pageName: TRACKING_USER_SUBSCRIPTION,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_SUBSCRIPTION_AND_BILLING,
    path: '/user/subscription-and-billing',
    pageName: TRACKING_USER_SUBSCRIPTION_AND_BILLING,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_SUBSCRIPTION_AND_BILLING_LOCALE,
    path: '/:locale/user/subscription-and-billing',
    pageName: TRACKING_USER_SUBSCRIPTION_AND_BILLING,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_BETA_LAB,
    path: '/user/beta-lab',
    pageName: TRACKING_USER_BETA_LAB,
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_USER_LOGOUT,
    path: '/logout',
    pageName: TRACKING_USER_LOGOUT,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_USER_REGISTER,
    path: '/register',
    pageName: TRACKING_USER_REGISTER,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_USER_REGISTER_LOCALE,
    path: '/:locale/register',
    pageName: TRACKING_USER_REGISTER,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_DEPRECATED_IMPORT_PORTFOLIO,
    path: '/user/portfolio/import',
    actions: [],
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_DEPRECATED_PORTFOLIO_RETURNS_REPORT,
    path: '/user/portfolio/calculation/:id/:name',
    actions: [],
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_DEPRECATED_PORTFOLIO_EDIT_TXN,
    path: '/user/portfolio/edittxn/:id/:name',
    actions: [],
    needsAuthentication: true,
    team: 'wombats',
  },
  {
    name: ROUTE_DEPRECATED_PORTFOLIO_SHARE,
    path: '/portfolio/shared-portfolios/:portfolioId/:secret',
    actions: [fetchPublicPortfolio.trigger],
    needsAuthentication: false,
    team: 'wombats',
  },
  {
    name: ROUTE_CREATE_CRITERIA,
    path: '/screener/create/:marketISO?/:industrySlug?/:name?',
    pageName: TRACKING_SCREENER,
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_EDIT_USER_IDEA,
    path: '/screener/edit/user/:id/:name?',
    pageName: TRACKING_SCREENER,
    needsAuthentication: true,
    team: 'dingoes',
  },
  {
    name: ROUTE_EDIT_EXPLORE_IDEA,
    path: '/screener/edit/explore/:id/:marketISO?/:industrySlug?',
    pageName: TRACKING_SCREENER,
    needsAuthentication: false,
    team: 'dingoes',
  },
  {
    name: ROUTE_ACCOUNT_RECOVERY,
    path: '/account-recovery',
    actions: [],
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_ACCOUNT_RECOVERY_LOCALE,
    path: '/:locale/account-recovery',
    actions: [],
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_OTP_LOGIN,
    path: '/otp-login/:token',
    actions: [],
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    cacheable: false,
  },
  {
    name: ROUTE_DELETE_ACCOUNT,
    path: '/account-delete/:token',
    actions: [],
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
    cacheable: false,
  },
  {
    name: ROUTE_STOCKS_HOME,
    path: '/stocks',
    pageName: TRACKING_STOCKS_HOME,
    actions: [detectMarket.trigger],
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_STOCKS_MARKET,
    path: `/stocks/:market/:sortBy(\\${sortByRouteMatcherRegex})?`,
    pageName: TRACKING_STOCKS_HOME,
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_STOCKS_MARKET_INDUSTRY,
    path: `/stocks/:market/:industry/:sortBy(\\${sortByRouteMatcherRegex})?`,
    pageName: TRACKING_STOCKS_HOME,
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_MAINTENANCE,
    path: '/maintenance',
    needsAuthentication: false,
  },
  {
    name: ROUTE_PLANS,
    path: '/plans',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: RUNTIME_ENV === 'native',
      mobileFooterMenu: RUNTIME_ENV === 'native',
      mobileTopbarSubNav: RUNTIME_ENV === 'native',
    },
    // temporary disable EFS cache while experiment is running
    // rely on CF page rules
    cacheable: false,
  },
  {
    name: ROUTE_ABOUT,
    path: '/about',
    needsAuthentication: false,
    actions: [],
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_CAREERS,
    path: '/careers',
    needsAuthentication: false,
    actions: [],
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_AFFILIATE_PROGRAM,
    path: '/affiliate-program',
    needsAuthentication: false,
    actions: [],
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_USER_UNSUBSCRIBE,
    path: '/unsubscribe',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_WATCHLIST,
    path: '/watchlist',
    pageName: TRACKING_WATCHLIST,
    needsAuthentication: true,
  },
  {
    name: ROUTE_WATCHLIST_WITH_EVENTS,
    pageName: TRACKING_WATCHLIST,
    path: '/watchlist/:subRoute?/:event/:eventId?',
    layout: {
      mobileTopbarSubNav: true,
    },
    needsAuthentication: true,
  },
  {
    name: ROUTE_PODCASTS,
    path: '/podcasts',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_TERMS_AND_CONDITIONS,
    path: '/termsandconditions',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_TERMS_AND_CONDITIONS_FULL,
    path: '/terms-and-conditions',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_PRIVACY_POLICY,
    path: '/privacypolicy',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_PRIVACY_POLICY_FULL,
    path: '/privacy-policy',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_ARTICLE,
    path: '/article/:slug',
    pageName: TRACKING_ARTICLE_PAGE,
    needsAuthentication: false,
  },
  {
    name: ROUTE_MARKET_HOME,
    path: '/markets',
    pageName: TRACKING_MARKET_PAGE,
    actions: [detectMarket.trigger],
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_MARKET_HUB,
    path: '/markets/insights',
    pageName: TRACKING_MARKET_HUB,
    needsAuthentication: false,
  },
  {
    name: ROUTE_MARKET,
    path: '/markets/:market',
    pageName: TRACKING_MARKET_PAGE,
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_MARKET_SECTOR,
    path: '/markets/:market/:sector',
    pageName: TRACKING_MARKET_PAGE,
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_MARKET_SECTOR_SECONDARY,
    path: '/markets/:market/:sector/:secondary',
    pageName: TRACKING_MARKET_PAGE,
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_MARKET_SECTOR_SECONDARY_TERTIARY,
    path: '/markets/:market/:sector/:secondary/:tertiary',
    pageName: TRACKING_MARKET_PAGE,
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  // the /business sub route is managed outside of mono for the
  // business login portal
  {
    name: ROUTE_BUSINESS,
    path: '/business',
    pageName: TRACKING_HOME,
    needsAuthentication: false,
  },
  {
    name: ROUTE_ACCOUNT_VALIDATION,
    path: '/account-validation',
    pageName: 'account-validation',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_ACCOUNT_VALIDATION_LOCALE,
    path: '/:locale/account-validation',
    pageName: 'account-validation',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_PRO_API,
    path: '/user/pro',
    pageName: 'token-management',
    needsAuthentication: true,
    layout: {
      mobileTopbarSubNav: true,
    },
  },
  {
    name: ROUTE_PUBLIC_NARRATIVE_CREATE,
    path: '/narratives/new',
    pageName: 'public-narrative-create',
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: false,
    },
    team: 'narwhals',
  },
  {
    name: ROUTE_PUBLIC_NARRATIVE_EDIT,
    path: '/narratives/edit/:id',
    pageName: 'public-narrative-edit',
    needsAuthentication: true,
    layout: {
      mobileTopbarSubNav: false,
    },
    team: 'narwhals',
  },
  {
    name: ROUTE_NARRATIVE_UPDATE_EDIT,
    path: '/narratives/edit/:narrativeid/updates/:updateid?',
    pageName: 'narrative-update-edit',
    needsAuthentication: true,
    team: 'narwhals',
  },
  {
    name: ROUTE_NARRATIVE,
    path: '/narratives/:slug',
    pageName: 'narrative',
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: false,
    },
    team: 'narwhals',
  },
  {
    name: ROUTE_NARRATIVE_COMMUNITY_DETAIL,
    path: '/community/narratives/:country/:industry/:exchange_ticker/:companyname/:slug',
    pageName: 'narrative',
    needsAuthentication: false,
    layout: {
      mobileTopbarSubNav: false,
    },
    team: 'narwhals',
  },
  {
    name: ROUTE_NARRATIVE_COMMUNITY_UPDATE_DETAIL,
    path: '/community/narratives/:country/:industry/:exchange_ticker/:companyname/:slug/updates/:updateslug',
    pageName: TRACKING_NARRATIVE_COMMUNITY,
    needsAuthentication: false,
    team: 'narwhals',
  },
  {
    name: ROUTE_NARRATIVE_COMMUNITY,
    path: '/community/narratives/:country?/:industry?/:exchange_ticker?/:companyname?',
    pageName: TRACKING_NARRATIVE_COMMUNITY,
    needsAuthentication: false,
    team: 'narwhals',
    cacheKey: ({ pathname, query }) => {
      if (query.after) return `${pathname}/after/${query.after}`;
      if (query.before) return `${pathname}/before/${query.before}`;
      return pathname;
    },
  },
  {
    name: ROUTE_NARRATIVE_COMMUNITY_PROFILE,
    path: '/community/users/:public_id',
    pageName: TRACKING_NARRATIVE_COMMUNITY_PROFILE,
    needsAuthentication: false,
    team: 'narwhals',
  },
  {
    name: ROUTE_SUBSCRIPTION_EXTENSION,
    path: '/subscription-extension',
    needsAuthentication: true,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_PASSWORD_RESET,
    path: '/password-reset',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_SOCIAL_TO_LOCAL,
    path: '/social-to-local',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_EMAIL_CHANGE,
    path: '/email-change',
    needsAuthentication: false,
    layout: {
      desktopTopbar: false,
      mobileTopbar: false,
      mobileFooterMenu: false,
    },
  },
  {
    name: ROUTE_GIFT_CARD_REDEEM,
    path: '/gift-card/redeem',
    pageName: TRACKING_GIFT_CARD_REDEEM,
    needsAuthentication: true,
    team: 'tesseracts',
  },
  {
    name: ROUTE_GIFT_CARD_PURCHASE,
    path: '/gift-card/purchase',
    needsAuthentication: false,
    pageName: TRACKING_GIFT_CARD_PURCHASE,
    team: 'tesseracts',
  },
];

export default function routesGenerator() {
  return mapRoutes(routes);
}
